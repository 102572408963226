<template>
    <div>
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <CalculatorRadio
                :form="form"
                label="moneyTypesOf"
                @radioChange="radioChange"
                :expenseType="expenseType"
                font="费用类型"
            ></CalculatorRadio>
            <CalculatorSelect
                :form="form"
                label="caseTypesOf"
                :data="caseTypesOfData"
                font="案件类型"
            ></CalculatorSelect>

            <CalculatorInputNumber
                :form="form"
                label="money"
                suffix="元"
                font="标的额"
            ></CalculatorInputNumber>
        </a-form-model>
        <ButtonView @start="start" @reset="reset"></ButtonView>
        <Info :mark="mark"></Info>
        <CalculatorResultClassification :result="result"></CalculatorResultClassification>
    </div>
</template>

<script>
    import CalculatorInputNumber from '@/components/PC/calculator/calculator-inputNumber.vue'
    import CalculatorRadio from '@/components/PC/calculator/calculator-radio.vue'
    import CalculatorSelect from '@/components/PC/calculator/calculator-select.vue'
    import ButtonView from '@/components/PC/calculator/button-view.vue'
    import Info from '@/components/PC/calculator/info.vue'
    import CalculatorResultClassification from '@/components/PC/calculator/calculator-result-classification.vue'
    // import { intervalCalculation } from './intervalCalculation'
    import { apiList } from '@/api/apiList'
    import * as Server from '@/api/server.js'

    export default {
        name: 'legalFare',
        components: {
            CalculatorInputNumber,
            CalculatorRadio,
            CalculatorSelect,
            ButtonView,
            Info,
            CalculatorResultClassification,
        },
        mounted() {
            this.$store.commit('setSpecific', '诉讼费计算器')
            this.getExpenseType()
            this.getMark()
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 },
                form: {
                    moneyTypesOf: '1',
                    caseTypesOf: '1-1',
                    money: '',
                },
                result: [],
                mark: '',
                expenseType: [],
                caseTypesOfData: [],
            }
        },
        methods: {
            // 注释
            async getMark() {
                let data = {
                    method: 'GET',
                    url: apiList.getCalculatorMark + '/1',
                    config: {
                        data: {},
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                this.mark = dataSource.data.returnData
            },

            // 注释
            async getExpenseType() {
                let data = {
                    method: 'GET',
                    url: apiList.getSusongExpenseType,
                    config: {
                        data: {},
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                dataSource.data.forEach((el) => {
                    el.caseTypes.forEach((item) => {
                        item.id = item.caseTypeId
                        item.name = item.caseTypeName
                    })
                })
                this.expenseType = dataSource.data
                this.form.moneyTypesOf = this.expenseType[0].expenseTypeId
                this.caseTypesOfData = this.expenseType[0].caseTypes
                this.form.caseTypesOf = this.expenseType[0].caseTypes[0].caseTypeId
            },

            async start(callback) {
                this.form.money = Number(this.form.money) || 0
                //请求数据
                let data = {
                    method: 'POST',
                    url: apiList.calculate + '/1 ',
                    config: {
                        data: {
                            expenseTypeId: this.form.moneyTypesOf,
                            caseTypeId: this.form.caseTypesOf,
                            targetNum: this.form.money,
                        },
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 400) {
                    this.$message.error(dataSource.data.returnInfo)
                    return
                }

                dataSource.data.returnData.results.forEach((el) => {
                    el.name = el.label
                    el.money = el.res
                })
                this.result = dataSource.data.returnData.results
                this.result.splice(0, 0)

                callback()
            },
            reset() {
                this.result = []
                this.caseTypesOfData = this.expenseType[0].caseTypes
                this.form = {
                    moneyTypesOf: this.expenseType[0].expenseTypeId,
                    caseTypesOf: this.expenseType[0].caseTypes[0].caseTypeId,
                    money: '',
                }
            },
            radioChange(target) {
                let index
                this.expenseType.forEach((item, i) => {
                    if (target.value == item.expenseTypeId) {
                        index = i
                    }
                })
                this.caseTypesOfData = this.expenseType[index].caseTypes
                this.form.caseTypesOf = this.expenseType[index].caseTypes[0].caseTypeId
                this.result = []
                this.form.money = ''
            },
        },
    }
</script>
