<template>
    <a-form-model-item :label="font">
        <a-radio-group v-model="form[label]" @change="radioChange">
            <a-radio :value="item.expenseTypeId" v-for="item in expenseType" :key="item">
                {{ item.expenseTypeName }}
            </a-radio>
        </a-radio-group>
    </a-form-model-item>
</template>

<script>
    export default {
        name: 'calculator-radio',
        props: ['form', 'label', 'font', 'expenseType'],
        methods: {
            radioChange(value) {
                this.$emit('radioChange', value.target)
            },
        },
    }
</script>

<style scoped lang="less">
    @import 'calculator.less';
    .ant-form-item {
        /deep/.ant-radio-group {
            .ant-radio {
                display: none;
            }
        }

        /deep/.ant-radio-wrapper span {
            min-width: 1.2rem;
            text-align: center;
            color: rgba(0, 0, 0, 0.65);
            line-height: 0.4rem;
            height: 0.4rem;
            display: block;
            border-radius: 0.06rem;
            font-size: 0.15rem;
            font-weight: 900;
            margin-right: 0.14rem;
            border: 1px solid #d9d9d9;
        }

        /deep/.ant-radio-wrapper-checked span {
            color: #fff !important;
            background: #358cf3 !important;
            border: 1px solid #358cf3;
        }

        // /deep/.ant-form-item-label > label::after {
        //     content: '';
        // }
    }
</style>
